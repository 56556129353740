.recharge {
  color: #11C55D;
}

.withdraw {
  color: #D94046;
}

.success {
  color: #11C55D;
  margin-right: 0.5rem;
  font-weight: 500;
}

.fail {
  color: #D94046;
  margin-right: 0.5rem;
  font-weight: 500;
}

.waiting {
  color: #FDB515;
  margin-right: 0.5rem;
  font-weight: 500;
}

.colorIcon {
  color: #8F8F8F;
}

.textColorGreen {
  color: #11C55D;
}

.font-weight-500 {
  font-weight: 500;
}

.reward {
  border: 1px solid #B1B1B1;
  border-radius: 100px;
  padding: 6px;
}

.cardBountyProgram {
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 24px;
  /* width: 450px; */
  height: 300px
}

.cardReferral {
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 24px;
  /* width: 450px; */
}