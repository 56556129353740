.ant-tabs {
    overflow: inherit;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 4px 16px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
    border-radius: 2px;
}

.ant-tabs-tab-btn {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;
}

.ant-tabs-tab-btn svg {
    margin-right: 8px;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
    display: none;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #FDB515;
    border: 1px solid #FDB515;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FDB515;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 16px;
}

.col-container{
    display: table;
    width: 100%;
}
.col-content{
    display: table-cell;
    padding: 16px;
}
